import { autocompleteClasses, Popper as MuiPopper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Popper = styled(MuiPopper, {
  shouldForwardProp: prop => prop !== 'insideDialog',
})(({ theme, insideDialog }) => ({
  ...(!insideDialog && { zIndex: `${theme.zIndex.popper} !important` }),
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    '& ul': {
      padding: 0,
      margin: 0,
    },

    [`& .${autocompleteClasses.option}`]: {
      minHeight: 36,
    },
  },
}));

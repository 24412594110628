import MuiListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';

export const EmptyLabel = styled(MuiListSubheader)(({ theme }) => ({
  backgroundColor: theme.palette.grayscale[100],
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  lineHeight: 'unset',
}));

export const ListSubheader = styled(MuiListSubheader)(({ theme }) => ({
  backgroundColor: theme.palette.grayscale[100],
  color: theme.palette.grayscale[800],
}));

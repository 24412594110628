import { Alert as MuiAlert, alertClasses, svgIconClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Alert = styled(MuiAlert)(({ theme }) => ({
  backgroundColor: theme.palette.grayscale[100],
  fontSize: theme.typography.pxToRem(12),
  marginBottom: theme.spacing(2.5),
  alignItems: 'center',
  [`& .${alertClasses.icon} .${svgIconClasses.root}`]: {
    marginBottom: 3,
  },
}));

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import useFormatMessage from '@/hooks/useFormatMessage';

import { Button } from './AddToBasketConfirmationDialog.style';

const AddToBasketConfirmationDialog = ({
  open,
  onAddAgain,
  onDontAddAgain,
  machineName,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <Dialog open={open} fullWidth maxWidth="sm" scroll="body">
      <DialogTitle data-cy="dialogTitle" align="center">
        {formatMessage('basket_addMachineConfirmationDialog_heading', {
          machineName: machineName || '',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText data-cy="dialogTextContent" align="center">
          {formatMessage('basket_addMachineConfirmationDialog_subheading')}
        </DialogContentText>
        <Stack spacing={2} mt={4} alignItems="center">
          <Button size="large" onClick={onAddAgain} fullWidth>
            {formatMessage(
              'basket_addMachineConfirmationDialog_addAgainButton'
            )}
          </Button>
          <Button
            size="large"
            color="primary"
            variant="text"
            onClick={onDontAddAgain}
            fullWidth>
            {formatMessage(
              'basket_addMachineConfirmationDialog_dontAddAgainButton'
            )}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

AddToBasketConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAddAgain: PropTypes.func.isRequired,
  onDontAddAgain: PropTypes.func.isRequired,
  machineName: PropTypes.string,
};

export default AddToBasketConfirmationDialog;

import { formHelperTextClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Button from '@/components/ui/Button/Button';
import Paper from '@/components/ui/Paper/Paper';

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: 540,

  [`.${formHelperTextClasses.root}`]: {
    marginBottom: theme.spacing(0.5),
  },
}));

export const MainWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2.5, 4, 4.5, 4),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 1.5, 4.5, 1.5),
  },
}));

export const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

export const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(1, 0, 2, 0),
  '& > *:not(:last-of-type)': {
    marginRight: theme.spacing(1.5),
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > *:not(:last-of-type)': {
      marginRight: 0,
      marginBottom: theme.spacing(1.5),
    },
  },
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  maxWidth: 300,

  [theme.breakpoints.down('sm')]: {
    maxWidth: 'initial',
  },
}));

export const DateRangePickerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > *': {
    flex: 1,
  },
  [theme.breakpoints.up('sm')]: {
    '& > *:not(:last-of-type)': {
      marginRight: theme.spacing(1.5),
    },
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const CounterWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

import { Typography } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import PropTypes from 'prop-types';
import React, { forwardRef, useContext, useEffect, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import useFormatMessage from '@/hooks/useFormatMessage';

import { EmptyLabel, ListSubheader } from './ProductListboxComponent.style';

const ITEM_SIZE = 36;
const EMPTY_LABEL_MOBILE_ITEM_SIZE = 52;
const LIST_SUBHEADER_ITEM_SIZE = 48;
const IDEAL_ITEM_COUNT = 10;
const MAX_LIST_HEIGHT = IDEAL_ITEM_COUNT * ITEM_SIZE;

const OuterElementContext = React.createContext({});

const OuterElementType = forwardRef(function OuterElementType(props, ref) {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = data => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const getHighlightedText = (name, query) => {
  const matches = match(name, query, {
    insideWords: true,
    findAllOccurrences: true,
  });
  const parts = parse(name, matches);

  return parts.map(({ text, highlight }, index) => (
    <Typography
      component="span"
      color={highlight ? 'common.blue' : 'text.primary'}
      fontSize="inherit"
      fontWeight="inherit"
      key={index}>
      {text}
    </Typography>
  ));
};

// Adapter for react-window
const ProductListboxComponent = forwardRef(function ProductListboxComponent(
  {
    children,
    inputRef,
    loadMoreItems,
    inputValue,
    hasNextPage,
    'data-cy': dataCy,
    ...other
  },
  ref
) {
  const formatMessage = useFormatMessage();
  const itemData = [];
  children.forEach(item => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const Row = ({ data, index, style }) => {
    const item = data[index];

    if (!item) {
      return null;
    }

    const itemProps = item[0];
    const { name, head, emptyLabel, selectManual } = item[1];

    if (emptyLabel) {
      return (
        <EmptyLabel
          key={itemProps.key}
          component="div"
          color="primary"
          data-cy={`PickListItem: empty label`}
          disableSticky
          style={style}>
          {name}
        </EmptyLabel>
      );
    }

    if (head) {
      return (
        <ListSubheader
          key={itemProps.key}
          component="div"
          disableSticky
          style={style}>
          {name}
        </ListSubheader>
      );
    }

    if (selectManual) {
      return (
        <Typography
          component="li"
          fontSize="inherit"
          variant="body2"
          color="common.blue"
          data-cy={`PickListItem: manual selection`}
          {...itemProps}
          noWrap
          style={style}
          sx={theme => ({
            backgroundColor: theme.palette.grayscale[100],
          })}>
          {formatMessage('autocomplete_select_manual_label', {
            query: inputValue,
          })}
        </Typography>
      );
    }

    return (
      <Typography
        component="li"
        fontSize="inherit"
        variant="body2"
        color="common.blue"
        data-cy={`PickListItem: ${index}`}
        {...itemProps}
        noWrap
        style={style}>
        <div>{getHighlightedText(name, inputValue)}</div>
      </Typography>
    );
  };

  const getItemSize = item => {
    if (!item) {
      return ITEM_SIZE;
    }

    if (item[1].emptyLabel) {
      return inputRef.current?.offsetWidth < 400
        ? EMPTY_LABEL_MOBILE_ITEM_SIZE
        : LIST_SUBHEADER_ITEM_SIZE;
    }

    if (item[1].head) {
      return LIST_SUBHEADER_ITEM_SIZE;
    }

    return ITEM_SIZE;
  };

  const getListHeight = () => {
    if (itemData.length > IDEAL_ITEM_COUNT) {
      return MAX_LIST_HEIGHT;
    }
    return itemData.map(getItemSize).reduce((a, b) => a + b, 0);
  };

  const itemCount = hasNextPage ? itemData.length + 1 : itemData.length;

  const isItemLoaded = index => !hasNextPage || index < itemData.length;

  const listRef = useResetCache(itemData);

  return (
    <div ref={ref} data-cy={dataCy || 'PickList'}>
      <OuterElementContext.Provider value={other}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}>
          {({ onItemsRendered, ref }) => (
            <List
              outerElementType={OuterElementType}
              innerElementType="ul"
              height={getListHeight()}
              itemCount={itemCount}
              itemData={itemData}
              itemSize={index => getItemSize(itemData[index])}
              onItemsRendered={onItemsRendered}
              ref={list => {
                ref(list);
                listRef.current = list;
              }}
              overscanCount={4}>
              {Row}
            </List>
          )}
        </InfiniteLoader>
      </OuterElementContext.Provider>
    </div>
  );
});

ProductListboxComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  inputRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  loadMoreItems: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  'data-cy': PropTypes.string,
};

ProductListboxComponent.defaultProps = {
  'data-cy': '',
};

export default ProductListboxComponent;
